import { createContext, useEffect, useState } from 'react'

export const StorageContext = createContext({})

export const StorageProvider = ({children}) => {
    
    const [storage, setStorage] = useState([])

    const storeNewPanel = (panel) => {

        panel.lastAccess = Date.now()

        const panels = [...storage]

        const index = panels.findIndex(el => el.shortId === panel.shortId)

        if(index >= 0) panels.splice(index, 1)

        panels.unshift(panel)

        //localStorage.setItem('panels', JSON.stringify(panels))
        setStorage(panels)
    } 

    const deletePanel = (shortId, setLocal = true) => {
        const panels = [...storage]

        const index = panels.findIndex(el => el.shortId === shortId)

        if(index >= 0) panels.splice(index, 1)

        //localStorage.setItem('panels', JSON.stringify(panels))
        if(setLocal) setStorage(panels)        
    }

    const getStoredPanels = () => {
        //const panels = localStorage.getItem('panels')
        //if(panels) setStorage(JSON.parse(panels))
    }

    useEffect(() => {
        //getStoredPanels()
    },[])

    return(
        <StorageContext.Provider value={{storage, storeNewPanel, deletePanel}}>
            {children}
        </StorageContext.Provider>
    )
}