import api from './api'

export const getDirectPanel = async (shortId) => {
    return await api.get(`directpanel/${shortId}`)
}

export const startPanel = async (id, data) => {
    return await api.put(`panel/start/${id}`, data)
}

export const stopPanel = async (id, data) => {
    return await api.put(`panel/stop/${id}`, data)
}