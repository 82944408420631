import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { getYoutubeId } from "../getYoutubeId";

const Slideshow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: black;
`

const Youtube = styled.iframe`
    display: flex;
    width: 100%;
    height: 100%;
    border: none;
`

export default function YoutubeExhibiton({url, muted, play}){

    return(
        <Slideshow>
            <Youtube
                src={`https://www.youtube.com/embed/${getYoutubeId(url)}?controls=0&autoplay=1&loop=1&playlist=${getYoutubeId(url)}&mute=${muted}`}
                allow="autoplay"
            />
        </Slideshow>
    )
}