import { useState, useEffect } from "react";
import styled from "styled-components";

const Slideshow = styled.div`
    height: 100%;
    width: 100%;
    background-color: white;
    background-image: url(${props => props.bgUrl});
    background-position: center;
    background-size: cover;
    -webkit-transition: background-image 1s ease-in-out;
    -moz-transition: background-image 1s ease-in-out;
    -o-transition: background-image 1s ease-in-out;
    transition: background-image 1s ease-in-out;
`

function Gallery({urls}){

    let timer

    const [galleryIndex, setGalleryIndex] = useState(0)
    const [galleryUrl, setGalleryUrl] = useState('')

    const updateIndex = () => {
        timer = !timer && setInterval(() => {
            if(galleryIndex === urls.length-1) setGalleryIndex(0)
            else setGalleryIndex(prev => prev + 1)
        }, 10000)
    }

    useEffect(() => {
        updateIndex()
        setGalleryUrl(urls[galleryIndex])
        return () => clearInterval(timer)
    },[galleryIndex])

    return(
        <Slideshow bgUrl={galleryUrl}/>
    )
}

export default Gallery