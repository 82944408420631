import { Box, Text, Spacer } from '../../../layout'

function PanelAlert({password, desk, color}) {

    return (
        <Box direction={'column'} align={'center'} justify={'center'} position={'absolute'} z_index={99999} height={100} background={'white'}>
            {
                password && desk &&
                <>
                    <Text size={'6vh'} color={color}>
                        Senha
                    </Text>
                    <Text size={'12vh'} weight={700} color={color}>
                        {password}
                    </Text>
                    <Spacer top={8}/>
                    <Text size={'6vh'} color={color}>
                        Guichê
                    </Text>
                    <Text size={'12vh'} weight={700} color={color}>
                        {desk}
                    </Text> 
                </>
            }
        </Box>
    )
}

export default PanelAlert