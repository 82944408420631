import { useState } from 'react';
import { Box, Text } from '../../../layout'
import Clock from 'react-live-clock';
import { useEffect } from 'react';

function Time({width, height}){

    const [date, setDate] = useState('')

    useEffect(() => {
        getDate()
    },[])

    const getMonthName = (month) => {
        switch (month) {
            case 0: return 'Janeiro'
            case 1: return 'Fevereiro'
            case 2: return 'Março'
            case 3: return 'Abril'
            case 4: return 'Maio'
            case 5: return 'Junho'
            case 6: return 'Julho'
            case 7: return 'Agosto'
            case 8: return 'Setembro'
            case 9: return 'Outubro'
            case 10: return 'Novembro'
            case 11: return 'Dezembro'
            default: break;
        }
    }

    const getDate = () => {
        const date = new Date()
        const day = date.getDate()
        const month = date.getMonth()
        const year = date.getFullYear()

        setDate(`${day.toString().padStart(2, '0')} de ${getMonthName(month)} de ${year}`)
    }
    
    return(
        <Box direction={'column'} background={'#37474F'} align={'center'} justify={'center'} width={width ? width : 20} height={height ? height : 100}>
            <Clock format={'HH:mm'} ticking={true} style={{fontSize: '3vh', color: 'white', fontWeight: '500'}}/>
            <Text size={'1.5vh'} color={'white'} weight={300}>{date}</Text>
        </Box>
    )
}

export default Time