import { createContext, useContext, useEffect, useState } from 'react'
import { getDirectPanel } from '../API/queries'
import { StorageContext } from './StorageProvider'

export const PanelContext = createContext({})

export const PanelProvider = ({children}) => {

    const { storeNewPanel } = useContext(StorageContext)
    
    const [panel, setPanel] = useState(null)
    const [panelConfig, setPanelConfig] = useState(null)
    const [userId, setUserId] = useState(null)
    const [locale, setLocale] = useState(null)

    const getPanel = async (shortId) => {

        try {
            const response = await getDirectPanel(shortId)

            setPanel(response.data.panel)
            setPanelConfig(response.data.config)
            setLocale(response.data.panel.locale)
            setUserId(response.data.panel.userId)

            //storeNewPanel({name: response.data.panel.name, shortId: response.data.panel.shortId})
            
            return response.status
        } catch (error) {
            console.log('error >>> ', error.response.status)
            return error.response.status
        }
    }

    const getPanel1 = async (shortId) => {

        try {
            const response = await getDirectPanel(shortId)

            setPanel(response.data.panel)
            setPanelConfig(response.data.config)
            setLocale(response.data.panel.locale)
            setUserId(response.data.panel.userId)

            //storeNewPanel({name: response.data.panel.name, shortId: response.data.panel.shortId})
            
            return response.data.config
        } catch (error) {
            console.log('error >>> ', error.response.status)
            return error.response.status
        }
    }
    
    return(
        <PanelContext.Provider value={{panel, getPanel, getPanel1, panelConfig, locale, userId}}>
            {children}
        </PanelContext.Provider>
    )
}