import { createContext, useContext, useEffect, useRef } from 'react'
import AWS from 'aws-sdk';
import awsConfig from '../aws-exports';
import { PanelContext } from './PanelProvider';

AWS.config.update(awsConfig);

export const VoiceContext = createContext({})

export const VoiceProvider = ({children}) => {

    const { panelConfig } = useContext(PanelContext)

    const config = useRef({})


    useEffect(() => {

        if(panelConfig){
            config.current = panelConfig
        }

    },[panelConfig])

    const getSpeech = (password, desk) => {

        function separateCharacters(input){
            return input.split('').map(char => `<say-as interpret-as="characters">${char}</say-as><break time="100ms"/>`).join('');
        }

        function generateFullCall() {
                      
            const ssml = `
                <speak>
                    Senha 
                    <prosody rate="medium">
                        ${separateCharacters(password)}
                    </prosody>
                    , comparecer no ${desk}.
                </speak>
            `
          
            return ssml;
        }

        function generateBriefCall() {
                      
            const ssml = `
                <speak>
                    <prosody rate="medium">
                        ${separateCharacters(password)}
                    </prosody>
                    <break time="500ms"/>
                    , ${desk}.
                </speak>
            `
          
            return ssml;
        }

        if(config.current.voice_type === 'full_call') return generateFullCall()
        else if(config.current.voice_type === 'brief_call') return generateBriefCall()
    }

    const playVoice = (password, desk) => {

        if(!config.current.voice_enable) return

        const polly = new AWS.Polly();
    
        const params = {
            OutputFormat: 'mp3',
            Text: getSpeech(password, desk),
            TextType: 'ssml',
            VoiceId: config.current.voice_id === 'Vitória' ? 'Vitoria' : config.current.voice_id,
            LanguageCode: 'pt-BR',
            Engine: 'neural'
        };
    
        polly.synthesizeSpeech(params, (err, data) => {
            if (err) {
                console.log(err, err.stack);
            } 
            else if (data.AudioStream) {
                const uInt8Array = new Uint8Array(data.AudioStream);
                const blob = new Blob([uInt8Array.buffer], { type: 'audio/mp3' });
                const url = URL.createObjectURL(blob);
        
                const audio = new Audio(url);
                
                audio.addEventListener('play', () => {});
                audio.addEventListener('ended', () => {
                    URL.revokeObjectURL(url);
                });
                
                audio.play();
            }
        })
    }
    
    return(
        <VoiceContext.Provider value={{playVoice}}>
            {children}
        </VoiceContext.Provider>
    )
}