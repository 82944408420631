import { Box, Text, Spacer } from '../../../layout'

function PanelMain({password, desk, color, height}) {
    return (
        <Box direction={'column'} align={'center'} justify={'center'} height={height && height} background={color}>
            {
                password && desk &&
                <>
                    <Text size={'3vh'} weight={300} color={'white'}>
                        Senha
                    </Text>
                    <Text size={'5vh'} weight={700} color={'white'}>
                        {password}
                    </Text>
                    <Spacer top={2}/>
                    <Text size={'3vh'} weight={300} color={'white'}>
                        Guichê
                    </Text>
                    <Text size={'5vh'} weight={700} color={'white'}>
                        {desk}
                    </Text>
                </>
            }
        </Box>
    )
}

export default PanelMain