import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: ${props => (props.height ? props.height + '%' : 'auto')};
    background-color: ${props => props.background ? props.background : 'transparent'};
`

export const HalfContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 45%;
    height: 100%;
`

export const Label = styled.h1`
    font-size: 2.5vh;
    font-weight: 300;
    color: white;
    text-align: center;
`

export const Password = styled.h1`
    font-size: 3.5vh;
    font-weight: 600;
    color: white;
    text-align: center;
    transform: scale(${props => props.scale ? props.scale : 1});
    transform-origin: center;
`