import { useContext, useState } from "react";
import SidePanelThreePlusMain from "./components/SidePanelThreePlusMain";
import SidePanelFour from "./components/SidePanelFour";
import Clock from './components/Clock'
import Ticker from "./components/Ticker";
import Gallery from "./components/Gallery";
import VideoExhibiton from "./components/VideoExhibiton";
import { Exhibition, Fullscreen, Panel, SidePanel, Widescreen, Footer } from "./style";
import { PanelContext } from "../../contexts/PanelProvider";
import { getColor } from "./Colors";
import { CallsContext } from "../../contexts/CallsProvider";
import PanelAlert from "./components/PanelAlert";
import YoutubeExhibiton from "./components/YoutubeExhibiton";
import { useEffect } from "react";
import { startPanel, stopPanel } from "../../API/queries";

export default function DirectPanel(){

    const { panel, getPanel1 } = useContext(PanelContext)
    const { call, passwords, showAlertPanel } = useContext(CallsContext)

    const [panelConfig, setPanelConfig] = useState(null)

    const recoverPanel = async () => {
        const params = new URLSearchParams(window.location.search)

        const shortId = params.get('id')

        setPanelConfig(await getPanel1(shortId))

    }

    useEffect(() => {
        recoverPanel()
        // document.body.requestFullscreen()
        // startPanel(panel._id, panel)

        // return () => stopPanel(panel._id, panel)
    },[])

    return(
        <>
            {
                panelConfig &&
                <Fullscreen>
                    <Widescreen center={!panelConfig.show_marquee}>
                        <Panel>
                            <Exhibition>
                                {
                                    panelConfig.exhibition !== 'none'  ?                        
                                    <>
                                        {
                                            panelConfig.exhibition === 'youtube' &&
                                            <YoutubeExhibiton url={panelConfig.youtube_url} muted={!panelConfig.video_sound} play={!showAlertPanel}/>
                                        }
                                        {
                                            panelConfig.exhibition === 'images' &&
                                            <Gallery urls={panelConfig.gallery_urls}/>
                                        }
                                        {
                                            panelConfig.exhibition === 'videos' &&
                                            <VideoExhibiton urls={panelConfig.videos_urls} muted={!panelConfig.video_sound} play={!showAlertPanel}/>
                                        }
                                        {
                                            showAlertPanel && 
                                            <PanelAlert color={getColor(panelConfig.theme, 600)} password={call.password} desk={call.desk}/>
                                        }
                                    </>
                                    :
                                    <PanelAlert color={getColor(panelConfig.theme, 600)} password={call?.password} desk={call?.desk}/>
                                }
                            </Exhibition>
                            <SidePanel>
                                {
                                    panelConfig.exhibition !== 'none' ?
                                    <SidePanelThreePlusMain theme={panelConfig.theme} data={passwords}/>
                                    :
                                    <SidePanelFour theme={panelConfig.theme} data={passwords}/>
                                }
                                {
                                    !panelConfig.show_marquee && panelConfig.show_time &&
                                    <Clock width={100} height={20}/>
                                }
                            </SidePanel>
                        </Panel>
                        {
                            panelConfig.show_marquee && 
                            <Footer>
                                {panelConfig.show_time && <Clock/>}
                                {panelConfig.show_marquee && <Ticker width={panelConfig.show_time ? 85 : 100} color={getColor(panelConfig.theme, 900)} text={panelConfig.marquee_text}/>}
                            </Footer>
                        }
                    </Widescreen>
                </Fullscreen>
            }
        </>
    )
}