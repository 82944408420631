import { useEffect, useRef, useState } from 'react';
import { Container, HalfContainer, Label, Password } from './style';

export default function SideLastCalledDisplay({ password, desk, color, height }) {
    
    const passwordRef = useRef(null);
    const deskRef = useRef(null);

    const [scale, setScale] = useState(1);

    useEffect(() => {
        const adjustScale = (ref) => {
            if (ref.current) {
                const parentWidth = ref.current.parentElement.offsetWidth;
                const childWidth = ref.current.scrollWidth;
                return childWidth > parentWidth ? parentWidth / childWidth : 1;
            }
            return 1;
        };

        const adjustAllScales = () => {
            const passwordScale = adjustScale(passwordRef);
            const deskScale = adjustScale(deskRef);
            const minScale = Math.min(passwordScale, deskScale);
            setScale(minScale);
        };

        adjustAllScales();

        const handleResize = () => {
            adjustAllScales();
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [password, desk]);

    return (
        <Container height={height} background={color}>
            {
                password && desk &&
                <>
                    <HalfContainer>
                        <Label>Senha</Label>
                        <Password ref={passwordRef} scale={scale}>
                            {password}
                        </Password>
                    </HalfContainer>
                    <HalfContainer>
                        <Label>Guichê</Label>
                        <Password ref={deskRef} scale={scale}>
                            {desk}
                        </Password>
                    </HalfContainer>
                </>
            }
        </Container>
    );
}
