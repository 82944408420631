import { useState, useRef, useEffect } from "react";
import styled from "styled-components";

const Slideshow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: black;
`

const Video = styled.video`
    display: flex;
    width: 100%;
    height: 100%;
`

export default function VideoExhibiton({urls, muted, play}){

    const [index, setIndex] = useState(0)

    const player = useRef('player')

    const updateIndex = () => {
        if(index === urls.length-1) return setIndex(0)
        setIndex(prev => prev + 1)
    }

    useEffect(() => {
        if(!play) player.current.pause()
        else player.current.play()
    },[play])

    return(
        <Slideshow>
            <Video
                ref={player}
                src={`${urls[index]}`}
                autoPlay
                muted={muted}
                onEnded={() => updateIndex()}
            />
        </Slideshow>
    )
}