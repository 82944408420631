import styled from "styled-components";

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: ${props => props.align && props.align};
    justify-content: ${props => props.justify && props.justify};
    margin-left: ${props => props.marginLeft && props.marginLeft};
    margin-right: ${props => props.marginRight && props.marginRight};
    width: ${props => (typeof props.width === 'string' ? props.width : typeof props.width === 'number' ? props.width+'\%' : 100+'\%')};
    max-width: ${props => (typeof props.width === 'string' ? props.width : typeof props.width === 'number' ? props.width+'\%' : 100+'\%')};
    height: ${props => props.height && props.height}%;
    border-bottom: ${props => props.borderBtm && '1px solid #ccc'};
    padding-bottom: ${props => props.paddingBtm && props.paddingBtm+'px'};
    padding-left: ${props => props.paddingLeft && props.paddingLeft+'px'};
`

export const Row = styled.span`
    display: flex;
    align-items: ${props => props.align ? props.align : 'none'};
    justify-content: ${props => props.justify ? props.justify : 'none'};
    width: 100%;
    height: ${props => (typeof props.height === 'string' ? props.height : typeof props.height === 'number' ? props.height+'\%' : 100+'\%')};
    padding-bottom: ${props => props.bottom && props.bottom}px;
    padding-top: ${props => props.top && props.top}px;
    padding-left: ${props => props.left && props.left}px;
    border-bottom-style: ${props => props.borderBtmStyle && props.borderBtmStyle};
    border-bottom-width: ${props => props.borderBtmWidth && props.borderBtmWidth+'px'};
    border-bottom-color: ${props => props.borderBtmColor && props.borderBtmColor};
    background-color: ${props => props.color && props.color};
`

export const Spacer = styled.div`
    margin-left: ${props => props.left}rem;
    margin-right: ${props => props.right}rem;
    margin-top: ${props => props.top}rem;
    margin-bottom: ${props => props.bottom}rem;
`

export const Text = styled.h1`
    display: flex;
    width: fit-content;
    max-width: 100%;
    align-items: ${props => props.align ? props.align : 'none'};
    justify-content: ${props => props.justify ? props.justify : 'none'};
    font-size: ${props => typeof props.size === 'number' ? props.size + 'rem' : props.size};
    font-weight: ${props => props.weight ? props.weight : 400};
    color: ${props => props.color ? props.color : '#37474F'};
    text-align: ${props => props.align};
    text-decoration: ${props => props.decoration};
    cursor: ${props => props.pointer && 'pointer'};
    margin: ${props => props.margin}rem;
    margin-bottom: ${props => props.bottom}rem;
    margin-left: ${props => props.left};
    margin-right: ${props => props.right};
    white-space: nowrap;
    overflow: hidden;
    height: ${props => props.height};
`

export const P = styled.p`
    font-size: ${props => props.size ? props.size : 1}rem;
    font-weight: ${props => props.weight ? props.weight : 400};
    color: ${props => props.color ? props.color : '#424242'};
    text-align: ${props => props.align && props.align};
    margin: ${props => props.margin && props.margin}rem;

    strong{
        font-weight: 800;
        color: #888;
    }
`

export const Icon = styled.div`
    display: flex;
    align-items: center;
    margin: ${props => props.margin ? props.margin : '5px'};
    width: ${props => props.width}rem;
    height: ${props => props.height}rem;
    padding-top: ${props => props.top && props.top}rem;
    padding-bottom: ${props => props.bottom && props.bottom}rem;
    padding-left: ${props => props.left && props.left}rem;
    padding-right: ${props => props.right && props.right}rem;
    cursor: ${props => props.pointer && 'pointer'};
    
    svg{
        color: ${props => props.color ? props.color : '#424242'};
        font-size: ${props => props.size && props.size}rem;
    }

    path{
        stroke: ${props => props.stroke};
    }
`

export const Input = styled.input`
    text-align: center;
    height: 3rem;
    width: 50%;
    margin: 1rem;
    padding: 0 1rem;
    border: none;
    border-radius: 0.5rem;
    outline: none;
    background-color: #efefef;
    color: #888;
    font-size: 1.2rem;
    font-weight: 600;

    &:hover{
        background-color: #e8e8e8;
    }
`

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
`

export const Box = styled.div`
    display: flex;
    flex-direction: ${props => props.direction && props.direction};
    position: ${props => props.position && props.position};
    align-items: ${props => props.align ? props.align : 'none'};
    justify-content: ${props => props.justify ? props.justify : 'none'};
    width: ${props => props.width ? props.width+'\%' : '100\%'};
    height: ${props => props.height && props.height}%;
    background-color: ${props => props.background && props.background};
    z-index: ${props => props.z_index && props.z_index};
    top:0;
`

export const ScrollBox = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: ${props => props.maxHeight && props.maxHeight};
    overflow: auto;

    &::-webkit-scrollbar{
        width: 14px;
    }

    &::-webkit-scrollbar-track{
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb{
        background: #CFD8DC;
        border: 3px solid white;
        border-radius: 7px;
    }

    &::-webkit-scrollbar-thumb:hover{
        background: #90A4AE;
    }
`