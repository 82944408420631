import { LoginBox, Logo, Page } from './style'
import logo from '../../img/panel-logo.png'
import rhomi from '../../img/logo_500x100.png'
import ShortIdAuth from './ShortIdAuth'
import { useState } from 'react'
import StoredPanelsDisplay from './StoredPanelsDisplay'

export default function Home(){

    const [display, setDisplay] = useState('shortid')
    
    return(
        <Page>
            <Logo src={logo}/>
            <LoginBox>
                {
                    display === 'shortid' ?
                    <ShortIdAuth onDisplayStored={() => setDisplay('stored')}/>
                    :
                    display === 'stored' &&
                    <StoredPanelsDisplay onDisplayShortIdAuth={() => setDisplay('shortid')}/>
                }
            </LoginBox>
            <Logo src={rhomi}/>
        </Page>
    )
}