import { useContext } from "react"
import { Text, Spacer, P, ScrollBox } from "../../layout"
import StoredPanelRow from "./StoredPanelRow"
import { StorageContext } from "../../contexts/StorageProvider"

export default function StoredPanelsDisplay({onDisplayShortIdAuth}){

    const { storage } = useContext(StorageContext)


    return (
        <>
            <Text size={1.5} weight={700} color={'#00BFA5'}>Histórico de Paineis Acessados</Text>
            <Spacer top={2}/>
            <P align={'center'} weight={500} color={'#aaa'}>Estes são os Paineis que foram <strong>acessados recentemente neste equipamento</strong>. Para iniciar um destes Paineis novamente basta <strong>clicar no botão Play ao lado</strong> do que deseja acessar.</P>
            <Spacer top={2}/>
            {
                storage.length > 0 ?
                <ScrollBox maxHeight={'20rem'}>
                    {
                        storage.map((panel, index) => {
                            return <StoredPanelRow key={index} data={panel}/>            
                        })
                    }
                </ScrollBox>
                :
                <Text size={1.4} color="#B0BEC5" weight={600}>Não há Paineis no Histórico</Text>
                }
            <Spacer top={2}/>
            <Text color={'#1565C0'} pointer decoration={'underline'} hoverWeight={600} onClick={() => onDisplayShortIdAuth()}>
                Acessar através do ID
            </Text>
        </>
    )
}

