import { Box, Text, Column } from '../../../layout'

function PanelMinor({password, desk, color, height}) {
    return (
        <Box align={'center'} justify={'center'} height={height && height} background={color}>
            {
                password && desk &&
                <>
                    {/* <Space width={180}/> */}
                    <Column align={'center'} width={40}>
                        <Text size={'2.5vh'} weight={300} color={'white'}>
                            Senha
                        </Text>
                        <Text size={'4vh'} weight={600} color={'white'}>
                            {password}
                        </Text>
                    </Column>
                    <Column align={'center'} width={40}>
                        <Text size={'2.5vh'} weight={300} color={'white'}>
                            Guichê
                        </Text>
                        <Text size={'4vh'} weight={600} color={'white'}>
                            {desk}
                        </Text>
                    </Column>
                    {/* <Space width={180}/> */}
                </>
            }
        </Box>
    )
}

export default PanelMinor