import { useContext, useState, useEffect } from "react";
import { Column, Icon, Row, Spacer, Text } from "../../layout";
import { timeString } from "./timeString"
import { BsFillPlayCircleFill, BsPlayCircle } from 'react-icons/bs'
import { BiErrorCircle } from 'react-icons/bi'
import { StorageContext } from "../../contexts/StorageProvider";
import { PanelContext } from "../../contexts/PanelProvider";
import { useNavigate } from 'react-router-dom'
import { PulseLoader } from 'react-spinners'

export default function StoredPanelRow({data}){

    const { name, shortId, lastAccess } = data
    const { deletePanel } = useContext(StorageContext)
    const { getPanel, panelConfig } = useContext(PanelContext)

    const [hoverIcon, setHoverIcon] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [canNavigate, setCanNavigate] = useState(false)

    const navigate = useNavigate()

    const handleConnect = async (shortId) => {

        setLoading(true)
        
        const status = await getPanel(shortId)

        if(status === 404) {
            setError(true)
            setLoading(false)
            //deletePanel(shortId, false)
            return
        }

        setCanNavigate(true)    
    }
    
    useEffect(() => {
        if(canNavigate && panelConfig) navigate('/panel')
    },[canNavigate, panelConfig])

    return (
        <Row align={'center'} top={10} bottom={10} borderBtmStyle={'solid'} borderBtmWidth={1} borderBtmColor={'#B0BEC5'}>
            <Column>
                <Row>
                    <Text size={1.2} weight={600}>{name}</Text>
                    <Spacer left={2}/>
                    <Text size={1.2} weight={300}>{`ID: ${shortId}`}</Text>
                </Row>
                {
                    error ?
                    <>
                        <Text color={'#C62828'} weight={600}>Não foi possível acessar este Painel</Text>
                        <Text color={'#C62828'} weight={600}>Provavelmente ele foi excluído.</Text>
                    </>
                    :
                    <>
                        <Text>{`Último Acesso: ${timeString(lastAccess)}`}</Text>
                        <Text color={'#1565C0'} pointer decoration={'underline'} hoverWeight={600} onClick={() => deletePanel(shortId)}>
                            Excluir
                        </Text>
                    </>
                }
                    
            </Column>
            <Column align={'flex-end'}>
                {
                    error ?
                    <Icon color={'#C62828'} size={2.5}>
                        <BiErrorCircle/>
                    </Icon>
                    :
                    <Icon 
                        color={'#00bfa5'} 
                        size={2} 
                        pointer 
                        onMouseEnter={() => setHoverIcon(true)} 
                        onMouseLeave={() => setHoverIcon(false)}
                        onClick={() => handleConnect(shortId)}
                    >
                        {
                            loading ?
                            <PulseLoader color={'#00bfa5'} loading={true} size={6}/>
                            :
                            hoverIcon ? 
                            <BsFillPlayCircleFill/>
                            :
                            <BsPlayCircle/>
                        }
                    </Icon>
                }
            </Column>
        </Row>
    )
}

