import PanelMinor from "./PanelMinor"
import { getColor } from "../Colors"
import { Box, Text } from '../../../layout'
import SideLastCalledDisplay from "./sideLastCalledDisplay"

function SidePanelFour({theme, data}){
    return(
        <>
            <Box height={7} align={'center'} justify={'center'} background={getColor(theme, 900)}>
                <Text size={'2vh'} color={'white'}>
                    Últimas chamadas
                </Text>
            </Box>
            <SideLastCalledDisplay color={getColor(theme, 600)} height={31} password={data[0]?.password} desk={data[0]?.desk}/>
            <SideLastCalledDisplay color={getColor(theme, 500)} height={31} password={data[1]?.password} desk={data[1]?.desk}/>
            <SideLastCalledDisplay color={getColor(theme, 400)} height={31} password={data[2]?.password} desk={data[2]?.desk}/>
            <SideLastCalledDisplay color={getColor(theme, 300)} height={31} password={data[3]?.password} desk={data[3]?.desk}/>
            {/* <PanelMinor color={getColor(theme, 600)} height={31} password={data[0]?.password} desk={data[0]?.desk}/>
            <PanelMinor color={getColor(theme, 500)} height={31} password={data[1]?.password} desk={data[1]?.desk}/>
            <PanelMinor color={getColor(theme, 400)} height={31} password={data[2]?.password} desk={data[2]?.desk}/>
            <PanelMinor color={getColor(theme, 300)} height={31} password={data[3]?.password} desk={data[3]?.desk}/> */}
        </>
    )
}

export default SidePanelFour