import { createContext, useContext, useEffect, useRef, useState } from 'react'
import io from 'socket.io-client'
import { PanelContext } from './PanelProvider'
import { getAlert } from '../alerts/alerts'
import { VoiceContext } from './VoiceProvider'

export const CallsContext = createContext({})

export const CallsProvider = ({children}) => {

    const { playVoice } = useContext(VoiceContext)
    const { panel, panelConfig } = useContext(PanelContext)

    const [call, setCall] = useState(null)
    
    const [passwords, _setPasswords] = useState([])
    const passwordsRef = useRef([])
    const setPasswords = (data) => {
        _setPasswords(data)
        passwordsRef.current = data
    } 

    const [showAlertPanel, setShowAlertPanel] = useState(false)

    const servicesRef = useRef([])
    const alertRef = useRef()
    const alertEnableRef = useRef()

    const alertPanelTimeoutRef = useRef(0)

    useEffect(() => {
        if(panel) {

            servicesRef.current = [...panel.services]

            alertRef.current = getAlert(panelConfig.alert)
            alertEnableRef.current = panelConfig.alert_enable
        }
    },[panel])
    
    useEffect(() => {
        const socket = io.connect(process.env.REACT_APP_API_KEY)

        socket.on('alert', (data) => {
            doAlert(data)
        })

        return () => socket.off('alert')
    },[])

    const doAlert = (data) => {
        const { password, desk, serviceId } = data

        if(!servicesRef.current.includes(serviceId)) return

        setCall({password, desk})
        refreshPanel(password, desk)
        playAlert(password, desk)
        showPanel()
    }

    const refreshPanel = (password, desk) => {

        const newPanel = [...passwordsRef.current]
        
        for (let i=0; i < newPanel.length; i++) {
            if(newPanel[i].password === password) return
        }

        const newCall = {password, desk}

        newPanel.unshift(newCall)
        if(newPanel.length === 5) newPanel.pop()

        setPasswords(newPanel)
    }

    const showPanel = () => {
        clearTimeout(alertPanelTimeoutRef.current)
        setShowAlertPanel(true)

        alertPanelTimeoutRef.current = setTimeout(() => {
            hideAlertPanel()
        }, 10000);
    }
    
    const hideAlertPanel = () => {
        clearTimeout(alertPanelTimeoutRef.current)
        setShowAlertPanel(false)
    }

    const playAlert = (password, desk) => {

        if (!alertEnableRef.current) return playVoice(password, desk)
        
        const alertAudio = alertRef.current
    
        if (!alertAudio) return
    
        const handleEnded = () => {
            playVoice(password, desk)
            alertAudio.removeEventListener('ended', handleEnded)
        };
    
        alertAudio.addEventListener('ended', handleEnded)
        alertAudio.play()
    }
    
    return(
        <CallsContext.Provider value={{call, passwords, showAlertPanel}}>
            {children}
        </CallsContext.Provider>
    )
}