import styled from 'styled-components'

export const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(135deg, #37474F 0%, #90A4AE 100%);
`

export const LoginBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 1.5rem;
    width: 40%;
    height: fit-content;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    color: #424242;
    padding: 4rem;
    transition-duration: 1s;
`

export const Logo = styled.img`
    width: 10%;
    object-fit: contain;
`

export const Submit = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 1rem;
    border: none;
    background-color: ${props => props.active ? '#00BFA5' : '#CFD8DC'};
    width: 50%;
    min-height: 4rem;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    color:  ${props => props.active ? 'white' : '#ECEFF1'};
    margin: 2rem 0;
    font-size: 16px;
    cursor: ${props => props.active && 'pointer'};

    &:hover{
        background-color: ${props => props.active && '#00897B'};
    }
`