import styled from 'styled-components'

export const Fullscreen = styled.div`
    display: flex;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: black;
`

export const Widescreen = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: ${props => props.center && 'center'};
    width: 100vw;
    aspect-ratio: 18/9;
    background-color: ${props => props.center ? 'black' : 'white'};
`

export const Panel = styled.div`
    display: flex;
    width: 100vw;
    height: ${props => props.full ? '100%' : '85%'};
` 

export const Exhibition = styled.div`
    height: 100%;
    aspect-ratio: 16/9;
    position: relative;
`

export const SidePanel = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

export const Footer = styled.div`
    display: flex;
    width: 100%;
    height: 15%;
`
