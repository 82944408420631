import React, { useContext, useRef, useEffect, useState } from "react";
import SidePanelThreePlusMain from "./components/SidePanelThreePlusMain";
import SidePanelFour from "./components/SidePanelFour";
import Clock from './components/Clock'
import Ticker from "./components/Ticker";
import Gallery from "./components/Gallery";
import VideoExhibiton from "./components/VideoExhibiton";
import { Exhibition, Fullscreen, Panel, SidePanel, Widescreen, Footer } from "./style";
import { PanelContext } from "../../contexts/PanelProvider";
import { getColor } from "./Colors";
import { CallsContext } from "../../contexts/CallsProvider";
import PanelAlert from "./components/PanelAlert";
import YoutubeExhibiton from "./components/YoutubeExhibiton";
import { startPanel, stopPanel } from "../../API/queries";

export default function MainPanel() {
    const { panel, panelConfig } = useContext(PanelContext);
    const { call, passwords, showAlertPanel } = useContext(CallsContext);

    const {
        theme, exhibition, gallery_urls, videos_urls, youtube_url,
        video_sound, show_time, show_marquee, marquee_text
    } = panelConfig;

    const exhibitionRef = useRef(null);
    const [exhibitionWidth, setExhibitionWidth] = useState("auto");

    const updateExhibitionWidth = () => {
        if (exhibitionRef.current) {
            const height = exhibitionRef.current.clientHeight;
            const width = height * (16 / 9);
            setExhibitionWidth(`${width}px`);
        }
    };

    useEffect(() => {
        document.body.requestFullscreen();
        startPanel(panel._id, panel);

        return () => stopPanel(panel._id, panel);
    }, [panel]);

    useEffect(() => {
        updateExhibitionWidth();
        window.addEventListener('resize', updateExhibitionWidth);

        return () => {
            window.removeEventListener('resize', updateExhibitionWidth);
        };
    }, [exhibitionRef]);

    return (
        <>
            {panelConfig &&
                <Fullscreen>
                    <Widescreen center={!show_marquee}>
                        <Panel>
                            <Exhibition ref={exhibitionRef} width={exhibitionWidth}>
                                {exhibition !== 'none' ?                        
                                    <>
                                        {exhibition === 'youtube' &&
                                            <YoutubeExhibiton url={youtube_url} muted={!video_sound} play={!showAlertPanel} />
                                        }
                                        {exhibition === 'images' &&
                                            <Gallery urls={gallery_urls} />
                                        }
                                        {exhibition === 'videos' &&
                                            <VideoExhibiton urls={videos_urls} muted={!video_sound} play={!showAlertPanel} />
                                        }
                                        {showAlertPanel && 
                                            <PanelAlert color={getColor(theme, 600)} password={call.password} desk={call.desk} />
                                        }
                                    </>
                                    :
                                    <PanelAlert color={getColor(theme, 600)} password={call?.password} desk={call?.desk} />
                                }
                            </Exhibition>
                            <SidePanel>
                                {exhibition !== 'none' ?
                                    <SidePanelThreePlusMain theme={theme} data={passwords} />
                                    :
                                    <SidePanelFour theme={theme} data={passwords} />
                                }
                                {!show_marquee && show_time &&
                                    <Clock width={100} height={20} />
                                }
                            </SidePanel>
                        </Panel>
                        {show_marquee && 
                            <Footer>
                                {show_time && <Clock />}
                                {show_marquee && <Ticker width={show_time ? 85 : 100} color={getColor(theme, 900)} text={marquee_text} />}
                            </Footer>
                        }
                    </Widescreen>
                </Fullscreen>
            }
        </>
    );
}
