import { Submit } from './style'
import { Text, P, Spacer, Input, Form, Row, Icon } from '../../layout'
import { useContext, useEffect, useState } from 'react'
import { PanelContext } from '../../contexts/PanelProvider'
import { MdOutlineAirplay } from 'react-icons/md'
import { PulseLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'
import { StorageContext } from '../../contexts/StorageProvider'

export default function ShortIdAuth({onDisplayStored}){

    const { getPanel, panelConfig } = useContext(PanelContext)
    const { storage } = useContext(StorageContext)

    const [shortId, setShortId] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const [canNavigate, setCanNavigate] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        setError(null)
    },[shortId])

    const handleIdInput = (value) => {
        if(isNaN(value) || value.length > 8) return
        setShortId(value)
    }

    const handleSubmit = async (e) => {

        e.preventDefault()

        if(!shortId) return setError('Insira o código do Painel corretamente')
        
        setLoading(true)
        
        const status = await getPanel(shortId)

        if(status === 404) {
            setError('Código inválido. Tente novamente!')
            setLoading(false)
            return
        }

        setCanNavigate(true)    
    }
    
    useEffect(() => {
        if(canNavigate && panelConfig) navigate('/panel')
    },[canNavigate, panelConfig])

    return (
        <>
            <Text size={1.5} weight={700} color={'#00BFA5'}>Bem-vindo ao Painel de Senhas Phila!</Text>
            <Spacer top={2}/>
            <P align={'center'} weight={500} color={'#aaa'}>Insira o <strong>ID do Painel*</strong> que deseja abrir no campo abaixo e clique em conectar</P>
            <Spacer top={2}/>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <Row align={'center'} justify={'center'}>
                    <Icon size={2} color={'#00BFA5'}>
                        <MdOutlineAirplay/>
                    </Icon>
                    <Input type={'text'} inputmode={'numeric'} value={shortId} onChange={(e) => handleIdInput(e.target.value)}/>
                </Row>
                {
                    error ?
                    <Text weight={600} color={'red'}>{error}</Text>
                    :
                    <Spacer top={1}/>
                }
                <Submit type='submit' active={shortId.length === 8}>
                    {
                        loading ? 
                        <PulseLoader color={'white'} loading={true} size={8}/>
                        :
                        'Conectar'
                    }
                </Submit>
            </Form>
            {
                storage?.length > 0 &&
                <Text color={'#1565C0'} pointer decoration={'underline'} hoverWeight={600} onClick={onDisplayStored}>
                    Ver Histórico de Painel Acessados
                </Text>
            }
            <Spacer top={2}/>
            <P align={'center'} weight={300} size={0.8} color={'#aaa'}>*O ID do Painel pode ser acessado através do Painel de Controle do sistema Phila através do menu lateral em "Dispositivos / Paineis"</P>
        </>
    )
}

