import { Box, Text } from '../../../layout'
import Marquee from "react-fast-marquee";

function Ticker({width, color, text}){

    return(
        <Box background={color} width={width}>
            <Marquee gradient={false} speed={100}>
                <Text size={'4vh'} color={'white'}>
                    {text}
                </Text>
            </Marquee>
        </Box>
    )
}

export default Ticker