export const timeString = (unix) => {

    const time = new Date(unix)

    const day = time.getDate()
    const month = time.getMonth() + 1
    const year = time.getFullYear()

    const hour = time.getHours()
    const min = time.getMinutes()
    const sec = time.getSeconds()

    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year} ${hour.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}` 
}