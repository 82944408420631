import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import MainPanel from "./pages/mainPanel";
import DirectPanel from './pages/directPanel';
import Home from "./pages/home";
import { PanelProvider } from "./contexts/PanelProvider";
import { CallsProvider } from './contexts/CallsProvider';
import { StorageProvider } from './contexts/StorageProvider';
import { VoiceProvider } from './contexts/VoiceProvider';

function App() {
  return (
    <StorageProvider>
      <PanelProvider>
        <VoiceProvider>
          <CallsProvider>
            <Router>
              <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="panel" element={<MainPanel/>}/>
                <Route path="direct" element={<DirectPanel/>}/>
              </Routes>
            </Router>
            </CallsProvider>
        </VoiceProvider>
      </PanelProvider>
    </StorageProvider>
  );
}

export default App;
