import PanelMinor from "./PanelMinor"
import PanelMain from "./PanelMain"
import { getColor } from '../Colors'
import { Box, Text } from "../../../layout"

function SidePanelThreePlusMain({theme, data}){

    return(
        <>
            <PanelMain color={getColor(theme, 600)} height={44} password={data[0]?.password} desk={data[0]?.desk}/>
            <Box height={5} align={'center'} justify={'center'} background={getColor(theme, 900)}>
                <Text size={'2vh'} color={'white'}>
                    Últimas chamadas
                </Text>
            </Box>            
            <PanelMinor color={getColor(theme, 500)} height={17} password={data[1]?.password} desk={data[1]?.desk}/>
            <PanelMinor color={getColor(theme, 400)} height={17} password={data[2]?.password} desk={data[2]?.desk}/>
            <PanelMinor color={getColor(theme, 300)} height={17} password={data[3]?.password} desk={data[3]?.desk}/>
        </>
    )
}

export default SidePanelThreePlusMain